import React from 'react';
import Modal from '../../../components/Modal';
import Quote from '../../../components/Quote';
const media = {
  secTitle: 'The Scale of Racism in America',
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/1DhdS0deNmo',
  },
};

export default props => (
  <Modal closeTo="Learned" media={media}>
    <p className="Title--2 Title--c">
      Many white people lack an understanding of the scale of racism in America,
      including our racial history and how it still permeates today's
      institutions.
    </p>
    <p>
      As a result, racism is often too narrowly defined as overt individual
      actions rather than systemic injustices. Residents pointed to the
      importance of creating a culture of responsibility—particularly among
      white people—in order to begin addressing social divisions. However, many
      white people do not feel like they bear the responsibility of addressing
      an issue they view as obsolete. Some white residents are aware of the
      inherent advantages that stem from their racial identity, while others
      fiercely reject the notion that they could be a part of the problem. Most
      white people we spoke to described racism as personal acts of malice,
      while most people of color spoke of the more harmful impacts that they
      face within biased systems.
    </p>
    <p>
      We also conclude that segregation continues to exist at a widespread level
      and there is a lack of dialogue around racism in many communities. Too
      often, individual stories of “making it” are lifted up (i.e. President
      Barack Obama or Oprah Winfrey) without sufficient attention paid to the
      structural barriers that people in marginalized groups must overcome to be
      successful. Furthermore, conversations about race and inequity rarely turn
      into action or results, so people get stuck in the cyclical nature of the
      discussion without a clear path forward.
    </p>
    <p>
      Based on our travels and existing bodies of research, it is clear that the
      perception of slavery’s legacy differs significantly based on racial and
      partisan lines. According to a December 2018 survey by Winthrop
      University, more than half of black Southerners report that they have been
      discriminated against in the last year because of their race, while only
      18 percent of white Southerners report such discrimination
    </p>
    <p>
      Compounding this is the idea that most Americans say the legacy of slavery
      still affects black people today. In early 2019, Pew Research Center asked
      adults about the legacy of slavery and found that 63 percent believe that
      it still affects the position of black people in American society today,
      either a great deal or a fair amount. However, the findings revealed that
      the impact of slavery on black Americans’ livelihood today is viewed quite
      differently by race. Of black respondents, 59 percent said that slavery
      has had a great deal of impact on their lives. Of white respondents, only
      26 percent shared this view. Similarly, Latino and Asian American
      respondents only marginally agreed more than white respondents at 29 and
      33 percent, respectively. When considering the question by political
      party, 43 percent of Democrats and those that lean Democratic said slavery
      has had a great deal of impact, compared to only 17 percent of Republicans
      and those that lean Republican.
    </p>
    <Quote
      quote="I can’t think of a way that race has not impacted my experience. It’s either greeted me at the front door, or greeted me on the way out."
      person="Black male, 38"
      title="Jackson"
    />
    <Quote
      quote="I call it acceptable anti-racism rhetoric. It’s okay to be against racism. But it’s not okay to take actual steps to challenge those who are perpetuating racist practices with those individual institutions."
      person="Black male, 63"
      title="Charleston, WV"
    />
    <Quote
      quote="Maybe race has affected my life and I just wasn’t aware of it because minorities were not treated real good for years, and I may have benefited from that. I hope I didn’t. But I suspect that there may have been a job or two over the years that I got that maybe someone else should have gotten simply because I am a Caucasian male."
      person="White male, 58"
      title="Charleston, WV"
    />
  </Modal>
);
